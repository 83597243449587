<template>
  <div>
    <my-section title="公司简介">
      <div slot="content" class="intro-cont">
        <p>优讯成立于2009年，以共铸金融服务新体验为使命，立志成为客户数智化经营领导者。我们始终秉持"诚信、勤勉、进取、创新"的核心价值观，为金融机构提供技术和运营的综合服务。我们专注于用数字化、智能化的方式帮助金融机构更好的经营客户，带动业务发展。我们以积分权益为营销媒介，以支付为连接工具，以场景为服务载体，以AI为智慧大脑，通过<i>“平台+运营”、“场景+生态”、“科技+金融”</i>的方式，帮助金融机构快速提升获客能力、服务能力和营销能力。</p>
        <p><i>十年百行</i>，我们以先进的理念和务实的实践引领行业发展，一直在细分市场领域处于领先地位。</p>
        <img src="~@/assets/images/about/introduction-20230206.png" />
        <p>优讯始终以“共铸金融服务新体验”为使命，以“成为客户数智化经营领导者”为目标，秉承专注、开放、引领的发展理念，勇于创新，持续积淀，臻于至善。</p>
      </div>
    </my-section>
    <my-section title="优讯风采">
      <div class="swiper-container intro-swiper" :class="{'lowie' : lowie}" id="js_introSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,i) in sliders" :key="i">
              <img :src="item" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
      </div>
    </my-section>
  </div>
</template>

<script>
import { mySection } from '@/components'
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css'
export default {
  components: {
    mySection
  },
  data () {
    return {
      lowie: false,
      sliders:[
        require('@/assets/images/about/intro-01.jpg'),
        require('@/assets/images/about/intro-02.jpg'),
        require('@/assets/images/about/intro-03.jpg'),
        require('@/assets/images/about/intro-04.jpg'),
        require('@/assets/images/about/intro-05.jpg'),
        require('@/assets/images/about/intro-06.jpg'),
        require('@/assets/images/about/intro-07.jpg'),
        require('@/assets/images/about/intro-08.jpg')
      ]
    }
  },
  mounted () {
    let t = this
    if(!t.$lowie){
      let slidesPerView = 3,
        spaceBetween = 15;
      if(window.screen.width <= 1200){
        slidesPerView = 1
        spaceBetween = 0
      }
      const swiper = new Swiper('#js_introSwiper', {
        loop: true,
        speed: 600,
        autoplay: 4000,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        slidesPerView: slidesPerView,
        spaceBetween: spaceBetween,
        freeMode: true,
        observer:true,        // 修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,  // 修改swiper的父元素时，自动初始化swiper
        onTouchEnd: function() {
          // touchend的时候轮播图可能还在滑动，立即执行startAutoplay以后不起作用
          // 所以加个时间
          setTimeout(swiper.startAutoplay,600)
        }
      })
    }
    else{
      t.lowie = true
    }
  },
  methods: {


  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .intro-cont{
    width: 1200px;
    margin: 0 auto;
    p{
      line-height: 40px;
      text-align: justify;
      font-size: 16px;
      text-indent: 2em;
      i{
        color: $color-main;
      }
    }
    img{
      display: block;
      width: 100%;
    }
  }
  .intro-swiper{
    width: 1200px;
    height: 320px;
    margin: 0 auto;
    cursor: pointer;
    img{
      display: block;
    }
    &.lowie{
      height: auto;
      padding-bottom: 30px;
      .swiper-wrapper{
        @extend %c;
      }
      .swiper-slide{
        float: left;
        width: 390px;
        margin-bottom: 15px;
        &:nth-of-type(3n-1){
          margin: 0 15px 15px;
        }
      }
    }
    .swiper-pagination.swiper-pagination-bullets{
      bottom: 50px;
      .swiper-pagination-bullet{
        width: 6px;
        height: 6px;
        background: $color-border;
        opacity: 1;
        margin: 0 2px;
        &.swiper-pagination-bullet-active{
          background: #28b670;
        }
      }
    }
  }

  @media screen and (max-width:1200px) {
    .intro-cont{
      width: auto;
      padding: 0 px2rem(15px);
      p{
        line-height: px2rem(24px);
        font-size: px2rem(13px);
        &+p{
          margin-top: px2rem(10px);
        }
      }
    }

    .intro-swiper{
      width: 92%;
      height: px2rem(250px);
      img{
        width: 100%;
      }
      .swiper-pagination.swiper-pagination-bullets{
        bottom: px2rem(15px);
        .swiper-pagination-bullet{
          width: px2rem(3px);
          height: px2rem(3px);
        }
      }
    }
  }
</style>
