<template>
  <my-section title="组织架构">
    <img src="~@/assets/images/about/structure.png" class="structure" />
  </my-section>
</template>

<script>
import { mySection } from '@/components'
export default {
  components: {
    mySection
  },
  data () {
    return {
      
    }
  },
  mounted () {
    // let t = this
    // alert(t.IEVersion())


  },
  methods: {


  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .structure{
    display: block;
    width: 1200px;
    margin: 0 auto 80px;
  }
  
  @media screen and (max-width:1200px) {
    .structure{
      width: 80%;
      margin: px2rem(20px) auto px2rem(40px);
    }
  }
</style>
